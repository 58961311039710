(function(wnd){
    function ReservationForm(container, params) {
        try {
            if (!container) {
                throw 'Container is required';
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }
            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    ReservationForm.prototype = {
        container: undefined,
        form: undefined,
        dealers: undefined,
        dealerField: undefined,
        dealerNameContainer: undefined,
        errorSummaryContent: undefined,
        sendedForm: false,
        handledRecaptcha: false,
        recaptchaSiteKey: '6LdjFfcUAAAAAJGn5EZ6aDMRs5MePdQLeXVbkEj5',
        texts: {},
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.form = this.container.find('form');
            this.button = this.container.find('[data-purpose="send-button"]');
            this.dealers = this.container.find('.dealer-row');
            this.dealerField = this.form.find('[name="dealer_id"]');
            this.dealerNameContainer = this.container.find('[data-purpose="dealer-name"]');
            this.errorSummaryContent = this.container.find('[data-purpose="top-error-content"]');
        },
        addDOMEvents: function() {
            var _self = this;

            this.form.on('submit', function(e){
                e.preventDefault();
                _self.sendForm();
            });

            this.button.click(function(e){
                e.preventDefault();
                _self.sendForm();
            });

            if (this.dealers && this.dealers.length > 0) {
                this.dealers.click(function(){
                    _self.dealers.removeClass('selected');
                    $(this).addClass('selected');

                    _self.dealerNameContainer.html($(this).data('name'));
                    _self.dealerField.val($(this).data('id'));
                });
            }

            this.container.find('.field').keyup(function(){
                var value = $(this).val();
                value = value.trim();

                if (value.length > 0 && !$(this).hasClass('has-value')) {
                    $(this).addClass('has-value');
                    $(this).parent().addClass('has-value');
                    _self.errorSummaryContent.find('[data-field="'+$(this).attr('name')+'"]').hide();
                } else if (value.length == 0 && $(this).hasClass('has-value')) {
                    $(this).removeClass('has-value');
                    $(this).parent().removeClass('has-value');
                    _self.errorSummaryContent.find('[data-field="'+$(this).attr('name')+'"]').show();
                }
            });
        },
        setParams: function(params) {
            if (params.texts) this.setTexts(params.texts);
        },
        setTexts: function(texts) {
            this.texts = $.extend({}, this.texts, texts);
        },
        sendForm: function() {
            if (!this.sendedForm) {
                var _self = this;
                this.sendedForm = true;

                if (!this.handledRecaptcha) {
                    this.handleRecaptcha();
                    return;
                }

                $.ajax({
                    url:this.form.attr('action'),
                    dataType:'json',
                    type:'post',
                    data: this.form.serialize(),
                    beforeSend: function() {
                        _self.container.find('.error-content').html('');
                        _self.container.find('.has-error').removeClass('has-error');
                        _self.container.find('.has-value').removeClass('has-value');
                    },
                    success: function(response) {
                        _self.handledRecaptcha = false;

                        if (response.success) {
                            if (response.url) {
                                document.location.href = response.url;
                            }
                        } else {
                            var scrollTop = null;
                            if (typeof response.errors !== 'undefined') {
                                $.each(response.errors, function(key, messages){
                                    if (key === 'summary') {
                                        _self.errorSummaryContent.addClass('has-error');
                                        // _self.errorSummaryContent.html(messages.join('<br/>'));

                                        var list = $('<ul>');

                                        $.each(messages, function(k, texts) {
                                            for (var i = 0; i < texts.length; i++) {
                                                list.append('<li data-field="'+k+'">'+texts[i]+'</li>');
                                            }
                                        });

                                        _self.errorSummaryContent.append(list);

                                    } else {
                                        var field = _self.form.find('[name="'+key+'"]');
                                        if (field && field.length > 0) {
                                            if (scrollTop === null || scrollTop > field.offset().top) {
                                                scrollTop = field.offset().top;
                                            }
                                            field.addClass('has-error');
                                            var parent = field.parent();
                                            parent.addClass("has-error");

                                            var errorContent = parent.find('.error-content');
                                            if (errorContent && errorContent.length === 1) {
                                                errorContent.html(messages.join('<br/>'));
                                            } 
                                        }
                                    }
                                });
                            }

                            if (scrollTop === null) {
                                scrollTop = _self.errorSummaryContent.offset().top;
                            }

                            $('html, body').animate({scrollTop: (scrollTop - 80)}, 400);
                        }
                    },
                    complete: function() {
                        setTimeout(function(){
                            _self.sendedForm = false;
                        }, 400);
                    }
                });
            }
        },
        handleRecaptcha: function() {
            var _self = this;

            this.form.find('[name="g-recaptcha-response"]').remove();
            this.form.find('[name="g-recaptcha-action"]').remove();

            grecaptcha.ready(function() {
                grecaptcha.execute(_self.recaptchaSiteKey, {action: 'reservation'}).then(function(token) {
                    _self.form.prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
                    _self.form.prepend('<input type="hidden" name="g-recaptcha-action" value="reservation">');
                    _self.sendedForm = false;
                    _self.handledRecaptcha = true;    
                    _self.sendForm();                            
                });
            });
        }
    };

    wnd.ReservationForm = ReservationForm;
})(window)
