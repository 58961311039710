(function(wnd){
    function ModelSelector(container, params) {
        try {
            if (!container) {
                throw 'Container is required';
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }
            
            this.init();
        } catch (e) {
            console.log(e);
        }
    }

    ModelSelector.prototype = {
        container:undefined,
        titleContainer: undefined,
        leadContainer: undefined,
        imageContainer: undefined,
        selectorContainer: undefined,
        facilitiesChanger: undefined,
        facilitiesParent: undefined,
        filterContent: undefined,
        selectedFacility: 1,
        modelName: undefined,
        modelId: 1, 
        facilities: {},
        configuratorUrl: undefined,
        options: {
            model: 'S90',
            model_url: 's90',
        },
        filterOptions: {
            engine: {
                key: 'engines',
                name: 'engine_id',
                label:'engine'
            }, 
            fuel: {
                key: 'fuels',
                name: 'fuel',
                label: 'fuel_select'
            },
            facilities: {
                key: 'facilities',
                name: 'facilities_id',
                label: 'facilities'
            }, 
            color: {
                key: 'color',
                name: 'color_id',
                label: 'color'
            }
        },
        texts: {
            fuel:'Motor / üzemanyag',
            transmission:'Sebességváltó',
            facilities: 'Felszereltség',
            wheel:'Keréktárcsák',
            color:'Szín',
            upholstery:'Kárpit',
            automatic_transmission: 'Automata',
            manual_transmission: 'Manuális',
            price: 'Ár',
            button_text: 'Kiválaszt',
            reserved_button_text: 'Lefoglalva',
            reserved_date_text: 'Érvényes: %DATE%',
            list_reference_id_text: 'Azonosító: %REFERENCE_ID%',
            valid: 'Érvényes',
            engine: 'Motor',
            fuel_select: 'Üzemanyag',
            filter_content_title: 'Szűrés paraméterek szerint',
            configurator: 'Konfigurátor',
            no_result: "Sajnos ebben az összeállításban nincs %MODEL% modellünk készleten. Módosítsa a feltételeket, vagy látogasson el weboldalunkra, ahol konfigurálhatja álmai Volvo-ját!"
        },
        color:{},
        engine:{},
        transmission:{},
        wheel:{},
        upholstery:{},
        fuels: {},
        cars:{},
        opened: false,
        init: function() {
            this.setElements();
            this.loadContent();
            this.addDOMEvents();
        },
        setElements: function() {
            this.titleContainer = this.container.find('[data-purpose="facilities-h1"]');
            this.leadContainer = this.container.find('[data-purpose="facilities-lead"]');
            this.imageContainer = this.container.find('[data-purpose="facilities-image"]');
            this.selectorContainer = this.container.find('[data-purpose="car-tables"]');
            this.facilitiesChanger = $('[data-purpose="facilities-changer"]');
            this.facilitiesParent = $('[data-purpose="facilities-parent"]');
            this.selectorContainer.parents('.cars-content').height(0);
            this.filterContent = $('[data-purpose="filter-content"]');
        },
        addDOMEvents: function() {
            var _self = this;

            this.facilitiesChanger.click(function(){
                if (_self.selectedFacility == $(this).data('facilities-id')) {
                    return;
                }

                // $(window).scrollTop(0);
                _self.facilitiesChanger.removeClass('active');
                _self.selectedFacility = $(this).data('facilities-id');
                $(this).addClass('active');
                _self.refreshContents();
            });

            var width = 0;
            this.facilitiesChanger.each(function(){
                width += $(this).outerWidth(true);
            });

            if (this.facilitiesParent) {
                this.facilitiesParent.css('min-width', width);
            }
        },
        refreshContents: function() {
            var facility = typeof this.facilities[this.selectedFacility] !== 'undefined' ? this.facilities[this.selectedFacility] : undefined;
            
            if (facility) {
                var title = (this.modelId == 4 ? 'V90' : this.getOption('model'))+' '+facility.name;
                this.titleContainer.html(title);
                this.leadContainer.html(facility.lead);
                
                var img = this.imageContainer.find('img');
                if (img.length > 0 && typeof facility.image != 'undefined' && facility.image !== null) {
                    var srcset = facility.image.mid+' 1x, '+facility.image.big+' 2x';
                    img.attr('srcset', srcset);
                }

                // document.title = title;
                // window.history.pushState({}, '', this.getOption('baseUrl')+'/'+facility.url);
            }
        },
        openOfferContent: function() {
            
            var outer = this.selectorContainer.parents('.cars-content');
            if (outer.length > 0) {
                outer.addClass('opened');
                var height = this.selectorContainer.outerHeight();
                outer.animate({height:height}, 500, 'swing', function(){
                    outer.removeAttr('style');
                });

                this.opened = true;
            }
        },
        setParams: function(params) {
            if (params.facilities) this.setFacilities(params.facilities);
            if (params.cars) this.setCars(params.cars);
            if (params.engines) this.setEngines(params.engines);
            if (params.transmissions) this.setTransmissions(params.transmissions);
            if (params.wheel) this.setWheel(params.wheel);
            if (params.color) this.setColor(params.color);
            if (params.upholstery) this.setUpholstery(params.upholstery);
            if (params.selectedFacility) this.setSelectedFacility(params.selectedFacility);
            if (params.options) this.setOptions(params.options);
            if (params.modelId) this.setModelId(params.modelId);
            if (params.fuels) this.setFuels(params.fuels);
            if (params.texts) this.setTexts(params.texts);
        },
        setFacilities: function(facilities) {
            this.facilities = $.extend(true, {}, this.facilities, facilities);
        },
        setSelectedFacility: function(selectedFacility) {
            this.selectedFacility = selectedFacility;
        },
        setCars: function(cars) {
            this.cars = $.extend({}, this.cars, cars);
        },
        setTexts: function(texts) {
            this.texts = $.extend({}, this.texts, texts);
        },
        setEngines: function(engines) {
            this.engines = $.extend({}, this.engines, engines);
        },
        setTransmissions: function(transmissions) {
            this.transmissions = $.extend({}, this.transmissions, transmissions);
        },
        setWheel: function(wheel) {
            this.wheel = $.extend({}, this.wheel, wheel);
        },
        setColor: function(color) {
            this.color = $.extend({}, this.color, color);
        },
        setUpholstery: function(upholstery) {
            this.upholstery = $.extend({}, this.upholstery, upholstery);
        },
        setOptions: function(options) {
            this.options = $.extend({}, this.options, options);
        },
        setFuels: function(fuels) {
            this.fuels = $.extend({}, this.fuels, fuels);
        },
        setModelId: function(modelId) {
            this.modelId = modelId;
        },
        getOption: function(key) {
            return typeof this.options[key] !== 'undefined' ? this.options[key] : null;
        },
        getText: function(key) {
            return typeof this.texts[key] !== 'undefined' ? this.texts[key] : key;
        },
        loadContent: function() {
            var _self = this;

            $.ajax({
                url: base_url+'car_controller/getDatasToSelector',
                type:'post',
                dataType:'json',
                data: {
                    model_id: _self.modelId,
                },
                success: function(response) {
                    _self.setParams(response);

                    _self.createFilterContent();
                    _self.createList();
                } 
            });
        },
        createList: function(cars) {
            this.selectorContainer.html('');
            var carKeys = cars ? Object.keys(cars) : Object.keys(this.cars);
            
            if (carKeys.length > 0) {
                if (this.selectorContainer.hasClass("no-result")) {
                    this.selectorContainer.removeClass("no-result");
                }
                for (var i = 0; i < carKeys.length; i++) {
                    this.selectorContainer.append(this.createCarRow(this.cars[carKeys[i]]));
                }
            } else {
                this.selectorContainer.html(this.createNoResultContent());
            }

            if (!this.opened) {
                this.openOfferContent();
            }
        },
        createCarRow: function(car) {
            var content = $('<div>', {
                class:'car-row model-row-'+car.model_id+(car.is_reserved ? ' reserved-row' : '')
            });

            var engine = typeof this.engines[car.engine_id] !== 'undefined' ? this.engines[car.engine_id] : undefined;
            var transmission = typeof this.transmissions[car.transmission_id] !== 'undefined' ? this.transmissions[car.transmission_id] : undefined;
            var facility = typeof this.facilities[car.facilities_id] !== 'undefined' ? this.facilities[car.facilities_id] : undefined;
            var color = typeof this.color[car.color_id] !== 'undefined' ? this.color[car.color_id] : undefined;
            var upholstery = typeof this.upholstery[car.upholstery_id] !== 'undefined' ? this.upholstery[car.upholstery_id] : undefined;

            content.append(this.getOfferStaticBox(engine.name+'<br/><span class="fuel-label">'+car.fuel, 'fuel'));
            content.append(this.getOfferStaticBox(transmission.name, 'transmission'));
            content.append(this.getOfferStaticBox(facility.name, 'facilities'));

            content.append(this.getSingleImageBox('color', color));
            content.append(this.getSingleImageBox('upholstery', upholstery));
            content.append(this.getPriceHtml(car.price));

            content.append(this.getButtonContent(car));

            return content;
        },
        getPriceHtml: function(price) {
            var content = $('<div>', {
                class:"car-table-box price-box",
            });

            content.append('<div class="title">'+this.getText('price')+'</div>');
            content.append('<div class="value"><span class="number">'+(price)+'</span><span class="currency">Ft</span></div>')

            return content;
        },
        getOfferStaticBox: function(value, title) {
            var box = $('<div>', {
                'class':'car-table-box static-box '+title+'-box',
            });

            box.append('<div class="title">'+(this.getText(title))+'</div>');
            box.append('<div class="value">'+(value)+'</div>');

            return box;
        },
        getSpriteBox: function(type, item) {
            var box = $('<div>', {
                'class':'car-table-box sprite-box '+type+'-box'
            });

            box.append('<div class="title">'+(this.getText(type))+'</div>');
            box.append('<div class="value"><span class="volvo-sprite '+type+'-sprite '+type+'-'+item.code.toLowerCase()+'" title="'+item.name+'"></span></div>')
            return box;
        },
        getSingleImageBox: function(type, item) {
            var path = this.getOption(type+'ImageDir');
            
            if (path) {
                var box = $('<div>', {
                    'class':'car-table-box sprite-box single-image-box '+type+'-box'
                });
    
                box.append('<div class="title">'+(this.getText(type))+'</div>');
                
                path = path.replace(/\/+$/, '');
                var code = item.code;

                switch (type) {
                    case 'color':
                        if (code.length == 3) {
                            code += '00';
                        }
                        break;
                    case 'upholstery':
                        if (code.length == 4) {
                            code += '00';
                        }
                        break;
                }
                
                var name = item.name;
                name = name.replace(/"/g, '&quot;');

                var src = path+'/'+code+'.png';
                box.append('<div class="value"><span class="image '+type+'-image" style="background-image:url(\''+src+'\')" title="'+name+'"></span></div>');

                return box;
            } else {
                return this.getSpriteBox(type, item);
            }
        },
        getButtonContent: function(car) {
            var box = $('<div>', {
                class:'button-box'
            });
            
            if (!car.is_reserved) {
                box.append('<a href="'+car.url+'" class="button"><span class="text">'+this.getText('button_text')+'</span>'+svg('arrow-right')+'</a>');
            } else {
                var dateText = this.getText('reserved_date_text');
                dateText = dateText.replace('%DATE%', car.reserved_date);

                var referenceText = this.getText('list_reference_id_text');
                referenceText = referenceText.replace('%REFERENCE_ID%', car.reference_id);
                box.append('<a href="javascript:void(0)" class="button empty-button reserved-button"><span class="text">'+this.getText('reserved_button_text')+'</span><span class="date">'+dateText+'</span><span class="date">'+referenceText+'</span></a>')
            }

            return box;
        },
        createFilterContent: function() {
            if (this.filterContent && this.filterContent.length > 0) {
                var optionKeys = Object.keys(this.filterOptions);
                var inputContent = $('<div>', {
                    class:'input-content'
                });
                var hasInput = false;
                if (optionKeys.length  > 0) {
                    for (var i = 0; i < optionKeys.length; i++) {
                        var option = this.filterOptions[optionKeys[i]];

                        var items = typeof this[option.key] !== 'undefined' ? this[option.key] : undefined;
                        if (items) {
                            var itemIds = Object.keys(items);

                            if (itemIds.length > 1) {
                                hasInput = true;

                                var box = $('<div>', {
                                    class:'filter-box '+option.key+'-box'
                                });

                                var select = $('<select>', {
                                    'name':option.name,
                                    'html':"<option value=\"\">"+this.getText(option.label)+"</option>"
                                });

                                for (var j = 0; j < itemIds.length; j++) {
                                    select.append('<option value="'+itemIds[j]+'">'+items[itemIds[j]].name+'</option>')
                                }

                                box.append(select);
                                inputContent.append(box);
                            }
                        } else {
                            option.key;
                        }
                    }
                }
                
                if (hasInput) {
                    var _self = this;
                    
                    this.filterContent.append('<div class="title">'+this.getText('filter_content_title')+'</div>');
                    this.filterContent.append(inputContent);

                    this.filterContent.find('select').change(function() {
                        _self.filterCars();
                    });
                } else {
                    this.filterContent.hide();
                    this.filterContent.parents('.cars-content').addClass("without-filter");
                }
            }
        },
        filterCars: function() {
            var params = {};

            this.filterContent.find('select').each(function() {
                if ($(this).val()) {
                    params[$(this).attr('name')] = $(this).val();
                }
            });

            var filterKeys = Object.keys(params);
            if (filterKeys.length > 0) {
                var cars = {};

                var carIds = Object.keys(this.cars);
                if (carIds) {
                    for (var i = 0; i < carIds.length; i++) {
                        var car = this.cars[carIds[i]];
                        var valid = true;

                        for (var j = 0; j < filterKeys.length; j++) {
                            var key = filterKeys[j];
                            var value = params[key];
                            
                            if (car[key] != value) {
                                valid = false;
                                break;
                            }
                        }

                        if (valid) {
                            cars[carIds[i]] = car;
                        }
                    }
                }

                this.createList(cars);
            } else {
                this.createList(this.cars);
            }
        },
        createNoResultContent: function() {
            this.selectorContainer.addClass("no-result");

            var content = $('<div>', {
                class:'no-result-content'
            });
            
            var text = this.getText('no_result');
            text = text.replace('%MODEL%', this.getOption('model'));

            content.append('<div class="text">'+text+'</div>');

            var link = this.getOption('configuratorUrl');
            if (link) {
                content.append('<div class="button-row"><a href="'+link+'" class="button empty-button" target="_blank"><span class="text">'+this.getText('configurator')+'</span>'+svg('arrow-right')+'</a></div>')
            }

            this.selectorContainer.append(content);
        }
    };

    wnd.modelSelector = ModelSelector;
})(window);
